<template>
  <v-container fluid>
    <!-- <v-card flat class="mb-3"> -->
    <v-form
      class="mb-6"
      style="width: 100%"
      v-model="valid"
      ref="form"
      v-if="scope"
    >
      <v-card>
        <div class="pa-2">
          <div class="d-flex flex-row">
            <div style="width: 50%" class="mx-2">
              <v-text-field
                :label="$t('name')"
                v-model="scope.scopeName"
                :rules="formRules.scopeName"
                :autofocus="true"
              />
              <v-text-field
                :label="$t('description')"
                v-model="scope.description"
                :rules="formRules.description"
              />
              <v-select
                :label="$t('clients')"
                v-model="scope.client"
                :items="clients"
                :menu-props="{ maxHeight: '400' }"
                multiple
              />
              <v-select
                :label="$t('resource-servers')"
                v-model="scope.resourceServer"
                :items="resourceServers"
                :menu-props="{ maxHeight: '400' }"
                multiple
              />
            </div>
          </div>

          <!-- errors -->
          <div class="d-flex flex-row" v-if="error">
            <span class="subtitle-1 mb-4 red--text"
              >{{ $t("form_invalid_text") }}: {{ error }}</span
            >
          </div>

          <!-- <actions> -->
          <div class="d-flex flex-row">
            <v-spacer />
            <v-btn class="ma-2" color="default" @click="cancel_click">
              <!-- <v-icon left>mdi mdi-plus</v-icon> -->
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              class="ma-2"
              color="primary"
              @click="save_click"
              :loading="loading"
            >
              <!-- <v-icon left>mdi mdi-plus</v-icon> -->
              {{ $t("save") }}
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
const fieldValidators = require("@/managers/fieldValidators");

export default {
  name: "ScopePage",
  components: {},
  props: {
    cancel: {
      type: Function,
      default: () => {}
    },
    save: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      mode: null,
      scope: null,
      formRules: {
        scopeName: [fieldValidators.requiredString],
        description: [fieldValidators.optionalString],
        client: [fieldValidators.optionalArray],
        resourceServer: [fieldValidators.optionalArray]
      },
      valid: true,
      error: null,
      loading: false,
      clients: [],
      resourceServers: []
    };
  },
  created: async function() {
    this.getClients();
    this.getResourceServers();
  },
  watch: {
    "$route.params.scopeId": {
      immediate: true,
      handler(v) {
        if (v === "new") {
          this.mode = "create";
          this.createScope();
          return;
        }
        this.mode = "edit";
        this.init();
        this.loadScope(v);
      }
    }
  },
  async mounted() {},
  methods: {
    async init() {
      await this.setBreadCrumbs();
    },
    createScope() {
      this.scope = {
        scopeId: null,
        scopeName: null,
        description: null,
        client: null,
        resourceServer: null
      };
    },
    async loadScope(scopeId) {
      const url = `${this.$store.state.idp_api_url}/scopes/${scopeId}`;
      const method = "GET";
      try {
        const response = await callAPI({ url, method });
        this.scope = response.data;
        // console.log(this.scope)
      } catch (err) {
        console.log(err);
      }
    },
    async cancel_click() {
      this.$router.go(-1);
    },
    async save_click() {
      this.error = null;

      this.valid = this.$refs.form.validate();

      if (!this.valid) {
        this.$dialog.error({
          title: this.$t("form_invalid_title"),
          text: this.$t("form_invalid_text"),
          actions: {
            true: this.$t("close")
          }
        });
        return;
      }

      let url = `${this.$store.state.idp_api_url}/scopes`;
      if (this.mode == "edit") url += `/${this.scope.scopeId}`;

      const method = this.mode == "create" ? "POST" : "PATCH";
      let data = {
        scopeName: this.scope.scopeName
      };

      if (this.scope.client && this.scope.client.length > 0) {
        data.client = this.scope.client;
      }

      if (this.scope.resourceServer && this.scope.resourceServer.length > 0) {
        data.resourceServer = this.scope.resourceServer;
      }

      if (this.scope.description) data.description = this.scope.description;

      try {
        this.loading = true;
        const response = await callAPI({ url, method, data });
        // console.log(response.status)
        if (response.status == 204 || response.status == 200) {
          this.$router.go(-1);
        }

        // this.client = response.data
        // console.log(this.client)
      } catch (err) {
        const data = err?.response?.data;
        // console.log(data);

        if (data.statusCode == 400) {
          // this.error = this.$t(data.message)
          this.error = this.$t(data.validation.keys.join(", "));
        }
      } finally {
        this.loading = false;
      }
    },
    async getClients() {
      const result = await callAPI({
        url: this.$store.state.idp_api_url + "/clients?limit=1000",
        method: "GET"
      });
      this.clients = result.data.map(p => ({
        value: p._uri,
        text: p.client_name
      }));
    },
    async getResourceServers() {
      const result = await callAPI({
        url: this.$store.state.idp_api_url + "/resource-servers?limit=1000",
        method: "GET"
      });
      this.resourceServers = result.data.map(p => ({
        value: p._uri,
        text: p.resourceName
      }));
    }
  }
};
</script>
